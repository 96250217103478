export const getRegionFromPoints = (points: any[]) => {
  if (points.length === 0) {
    return { latitudeDelta: 0, longitudeDelta: 0 };
  }

  let minLat = points[0].latitude;
  let maxLat = points[0].latitude;
  let minLon = points[0].longitude;
  let maxLon = points[0].longitude;

  let lat = 0;
  let lon = 0;

  points.forEach((point) => {
    minLat = Math.min(minLat, point.latitude);
    maxLat = Math.max(maxLat, point.latitude);
    minLon = Math.min(minLon, point.longitude);
    maxLon = Math.max(maxLon, point.longitude);
    lat += point.latitude;
    lon += point.longitude;
  });

  lat = lat / points.length;
  lon = lon / points.length;

  const latDelta = Math.abs(maxLat - minLat) * 1.2;
  const lonDelta = Math.abs(maxLon - minLon) * 1.2;

  return {
    latitudeDelta: latDelta,
    longitudeDelta: lonDelta,
    latitude: lat,
    longitude: lon,
  };
};

export const calculateZoom = (
  latitudeDelta: number,
  longitudeDelta: number,
  height: number,
  width: number
) => {
  const WORLD_DIM = { height: height, width: width };

  const angle = Math.max(latitudeDelta, longitudeDelta);
  const zoom = Math.floor(Math.log2((WORLD_DIM.height * 360) / angle / 256));

  return zoom;
};
