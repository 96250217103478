import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { TrackingPage } from "./routes/tracking";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ShortLinkPage } from "./routes/shortLink";

function App() {
  const router = createBrowserRouter([
    {
      path: "/tracking",
      element: <TrackingPage />,
    },
    {
      path: "/t/:hash",
      element: <ShortLinkPage />,
    },
  ]);
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ""}>
      <RouterProvider router={router} />
    </APIProvider>
  );
}

export default App;
