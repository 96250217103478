import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { socket } from "../utils/socket";
import { Map, Marker, useMap } from "@vis.gl/react-google-maps";
import "./tracking.css";
import styled from "styled-components";
import axios from "axios";
import { IServices } from "../utils/schemas/services";
import { calculateZoom, getRegionFromPoints } from "../utils/maps";
import { PolyLine } from "../componentes/polyline";
import { DEFAULT_IMAGE } from "../utils/constants";
import { colors } from "../utils/colors";
import { useLangs } from "../hooks/useLangs/useLangs";

const TrackingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  flex-grow: 1;
  height: 100%;
`;

const HeaderCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 14px;
  position: absolute;
  bottom: 0;
  background-color: white;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-vertical: 5px;
  object-fit: cover;
`;

const Text = styled.span`
  max-width: 50px;
  font-size: 10px;
  text-align: center;
`;

const TextTitle = styled.span`
  font-weight: 500;
  margin-bottom: 3px;
`;

const TextDescription = styled.span`
  font-weight: 200;
  margin-bottom: 3px;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 8px;
  flex-direction: column;
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TextPrice = styled.span`
  font-weight: 400;
  color: ${colors.blue};
  margin-bottom: 3tx;
`;

export const TrackingPage = () => {
  const translate = useLangs();
  const map = useMap("service-map");
  const ref = useRef(null);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [zoom, setZoom] = useState(0);
  const [service, setService] = useState<Partial<IServices | any>>();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server");
    });
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_LINK}/api/service`, {
          headers: {
            authorization: searchParams.get("token"),
          },
        })
        .then(({ data }) => {
          const findCurrentService = data?.currentServices?.[0];
          if (findCurrentService) {
            setService(findCurrentService);
          }
        });
    }
  }, [searchParams]);

  useEffect(() => {
    if (service?.driver?._id) {
      socket.emit("join-live-location", { driverId: service?.driver?._id });
    }
    return () => {
      socket.emit("leave-live-location", { driverId: service?.driver?._id });
    };
  }, [service?.driver?._id]);

  useEffect(() => {
    socket.on("live-location", ({ coords }) => {
      setService((service: any) => {
        const newService = {
          ...service,
          driver: {
            ...service?.driver,
            initLocationDriver: {
              ...service?.driver?.initLocationDriver,
              coords: [coords.longitude, coords.latitude],
            },
          },
        };
        return newService;
      });
    });
  }, []);

  const configureDirections = async () => {
    const startLocation = {
      latitude: service?.startLocation?.coords[1],
      longitude: service?.startLocation?.coords[0],
    };
    const destinationLocation = {
      latitude: service?.destinationLocation?.coords[1],
      longitude: service?.destinationLocation?.coords[0],
    };

    const initLocationDriverLocation = {
      latitude: service?.driver?.initLocationDriver?.coords?.[1],
      longitude: service?.driver?.initLocationDriver?.coords?.[0],
    };

    const points = [startLocation];
    if (destinationLocation.longitude && destinationLocation.latitude) {
      points.push(destinationLocation);
    }
    if (
      initLocationDriverLocation.longitude &&
      initLocationDriverLocation.latitude
    ) {
      points.push(initLocationDriverLocation);
    }

    const mapElement: any = ref.current;

    const zoom = getRegionFromPoints(points);
    setCenter({
      lat: zoom?.latitude ?? 0,
      lng: zoom?.longitude ?? 0,
    });
    setZoom(
      calculateZoom(
        zoom.latitudeDelta * 1.2,
        zoom.longitudeDelta * 1.2,
        mapElement?.clientWidth,
        mapElement?.clientHeight
      )
    );
  };

  useEffect(() => {
    if (service) {
      configureDirections();
    }
  }, [
    service?.startLocation?.coords,
    service?.destinationLocation?.coords,
    service?.driver?.initLocationDriver,
  ]);

  const routeCoordinates = useMemo(() => {
    const route: google.maps.LatLngLiteral[] =
      service?.coordinates?.map(([longitude, latitude]: number[]) => ({
        lat: latitude,
        lng: longitude,
      })) ?? [];
    return route;
  }, [service?.coordinates]);

  const routeCoordinatesDriver = useMemo(() => {
    const route: google.maps.LatLngLiteral[] =
      service?.coordinatesDriver?.map(([longitude, latitude]: number[]) => ({
        lat: latitude,
        lng: longitude,
      })) ?? [];
    return route;
  }, [service?.coordinatesDriver]);

  const handleCopyYappy = () => {
    if (service?.driver?.settings?.yappy) {
      navigator.clipboard.writeText(service?.driver?.settings?.yappy);
      alert(translate("yappy-copied"));
    }
  };

  return (
    <TrackingContainer>
      <Container ref={ref}>
        <Map
          id="service-map"
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          onBoundsChanged={(event: any) => {
            setCenter(event.detail.center);
            setZoom(event.detail.zoom);
          }}
          center={center}
          zoom={zoom}
        >
          {service?.startLocation?.coords[1] &&
            service?.startLocation?.coords[0] && (
              <Marker
                position={{
                  lat: service?.startLocation?.coords[1],
                  lng: service?.startLocation?.coords[0],
                }}
                icon={{
                  url:
                    "data:image/svg+xml;charset=UTF-8," +
                    encodeURIComponent(
                      `<svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      fill="currentColor"
                      height="2em"
                      width="2em"
                    >
                      <path d="M208 48c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm-56 304v128c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9l-28.6 47.6c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l44.9 74.7c-16.1 17.6-28.6 38.5-36.6 61.5-1.9-1.8-3.5-3.9-4.9-6.3L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352h-16zm424 16c0 79.5-64.5 144-144 144s-144-64.5-144-144 64.5-144 144-144 144 64.5 144 144zm-76.7-43.3c-6.2-6.2-16.4-6.2-22.6 0L416 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" />
                    </svg>
                    `
                    ),
                }}
              />
            )}
          {service?.destinationLocation?.coords[1] &&
            service?.destinationLocation?.coords[0] && (
              <Marker
                position={{
                  lat: service?.destinationLocation?.coords[1],
                  lng: service?.destinationLocation?.coords[0],
                }}
                icon={{
                  url:
                    "data:image/svg+xml;charset=UTF-8," +
                    encodeURIComponent(
                      `<svg width="1.5em" height="1.5em" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16.5" cy="16.5" r="12.5" stroke="black" stroke-width="8"/>
                    </svg>
                    `
                    ),
                }}
              />
            )}
          {service?.driver?.initLocationDriver?.coords[1] &&
            service?.driver?.initLocationDriver?.coords[0] && (
              <Marker
                position={{
                  lat: service?.driver?.initLocationDriver?.coords[1],
                  lng: service?.driver?.initLocationDriver?.coords[0],
                }}
                icon={{
                  url:
                    "data:image/svg+xml;charset=UTF-8," +
                    encodeURIComponent(
                      `<svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                      fill="currentColor"
                      height="2em"
                      width="2em"
                    >
                      <path d="M959 413.4L935.3 372a8 8 0 00-10.9-2.9l-50.7 29.6-78.3-216.2a63.9 63.9 0 00-60.9-44.4H301.2c-34.7 0-65.5 22.4-76.2 55.5l-74.6 205.2-50.8-29.6a8 8 0 00-10.9 2.9L65 413.4c-2.2 3.8-.9 8.6 2.9 10.8l60.4 35.2-14.5 40c-1.2 3.2-1.8 6.6-1.8 10v348.2c0 15.7 11.8 28.4 26.3 28.4h67.6c12.3 0 23-9.3 25.6-22.3l7.7-37.7h545.6l7.7 37.7c2.7 13 13.3 22.3 25.6 22.3h67.6c14.5 0 26.3-12.7 26.3-28.4V509.4c0-3.4-.6-6.8-1.8-10l-14.5-40 60.3-35.2a8 8 0 003-10.8zM264 621c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm388 75c0 4.4-3.6 8-8 8H380c-4.4 0-8-3.6-8-8v-84c0-4.4 3.6-8 8-8h40c4.4 0 8 3.6 8 8v36h168v-36c0-4.4 3.6-8 8-8h40c4.4 0 8 3.6 8 8v84zm108-75c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zM220 418l72.7-199.9.5-1.3.4-1.3c1.1-3.3 4.1-5.5 7.6-5.5h427.6l75.4 208H220z" />
                    </svg>
                    `
                    ),
                }}
              />
            )}

          <PolyLine map={map} path={routeCoordinates} color="#2273f5" />
          <PolyLine map={map} path={routeCoordinatesDriver} color="green" />
        </Map>
      </Container>
      {service?.driver && (
        <HeaderCardContainer onClick={handleCopyYappy}>
          <UserContainer>
            <ProfileImage src={service.driver.profileImage ?? DEFAULT_IMAGE} />
            <Text>
              {service?.driver?.name} {service?.driver?.lastname}
            </Text>
          </UserContainer>
          <RightContainer>
            <TextTitle>{service?.startLocation?.name}</TextTitle>
            <TextDescription>
              {service?.destinationLocation?.name}
            </TextDescription>
            <TextDescription>
              {service?.driver?.vehicle?.make} {service?.driver?.vehicle?.model}{" "}
              {service?.driver?.vehicle?.color} {service?.driver?.vehicle?.year}
            </TextDescription>
            <PriceContainer>
              <TextPrice>${service?.price?.toFixed(2)}</TextPrice>
              {service?.distanceToLocationMeters ? (
                <TextPrice>
                  {service?.distanceToLocationMeters < 1000
                    ? service?.distanceToLocationMeters.toFixed(0)
                    : Math.round(
                        service?.distanceToLocationMeters / 1000
                      ).toFixed(0)}{" "}
                  {service?.distanceToLocationMeters < 1000
                    ? translate("meters")
                    : translate("kilometers")}
                </TextPrice>
              ) : null}
              {service?.driver?.settings?.yappy ? (
                <TextDescription>
                  Yappy: {service?.driver?.settings?.yappy}
                </TextDescription>
              ) : null}
            </PriceContainer>
          </RightContainer>
        </HeaderCardContainer>
      )}
    </TrackingContainer>
  );
};
