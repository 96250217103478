import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

// bonkers IMO that this isn't a method on MVCArray, but here we are
function pathsDiffer(
  path1: google.maps.MVCArray<google.maps.LatLng>,
  path2: google.maps.LatLngLiteral[] | any
): boolean {
  if (path1.getLength() != path2.length) return true;
  for (const [i, val] of path2.entries())
    if (path1.getAt(i).toJSON() != val) return true;
  return false;
}

// React component for map polyline.
export function PolyLine(props: {
  map: google.maps.Map | null;
  path: google.maps.LatLngLiteral[];
  color: string;
}) {
  const [polyline, setPolyline] = useState<google.maps.Polyline | null>(null);
  useDeepCompareEffect(() => {
    // Create polyline after map initialized.
    if (!polyline && props.map)
      setPolyline(
        new google.maps.Polyline({
          strokeColor: props.color,
        })
      );

    // Synchronize map polyline with component props.
    if (polyline && polyline.getMap() != props.map) polyline.setMap(props.map);
    if (polyline && pathsDiffer(polyline.getPath(), props.path))
      polyline.setPath(props.path);

    return () => {
      // Cleanup: remove line from map
      if (polyline) polyline.setMap(null);
    };
  }, [props, polyline]);

  return null;
}
