import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ShortLinkPage = () => {
  const { hash } = useParams();
  useEffect(() => {
    if (hash) {
      console.log(hash);
      axios
        .get(`${process.env.REACT_APP_API_LINK}/api/short-link/${hash}`)
        .then(({ data }) => {
          window.location.href = data.url;
        });
    }
  }, [hash]);
  return null;
};
