export const colors = {
    blue: '#2273f5',
    gray: '#e0e0e0',
    white: 'white',
    red: 'red',
    gray2: '#ECE5DD',
    black: 'black',
    gray3: '#a8a8a8',
    yellow: '#ffe234',
    gray4: '#ddd',
    gray5: '#f0f0f0',
    black1: '#272c33',
    black2: '#12161a',
}