import { useCallback } from "react";
import { langs } from "./langst";

export const useLangs = () => {
  const translate = useCallback((path: string, returnUndefined?: boolean) => {
    const language = navigator.language.substring(0, 2) ?? "es";
    return langs?.[language]?.[path] ?? (returnUndefined ? undefined : path);
  }, []);

  return translate;
};
